@import url("../../Css/Animation.css");

.benefit {
    padding-top: 120px;
    padding-bottom: 120px;
    &__body {
        max-width: 550px;
        width: 100%;
        font-size: 14px;
        line-height: 150%;
    }
    &__header {
        font-size: 35px;
        font-weight: bold;
        margin-top: 12px;
        line-height: 150%;
        margin-right: 160px;
        animation: slideInFromBottom 1s ease-out;
        text-transform: capitalize;
    }
    &__image {
        margin-bottom: 25px;
    }
    &__item {
        max-width: 384px;
        margin-top: 20px;
        transition: all 0.3s ease;
        &:hover {
            transform: scale(1.05);
        }
    }
    &__item-body {
        color: #596780;
    }
    &__item-header {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 12px;
        color: #040815;
    }
    &__list {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__subheader {
        font-size: 20px;
        color: #8e00af;
    }
    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 65px;
    }
}

@media only screen and (max-width: 1200px) {
    .benefit {
        &__header {
            font-size: 25px;
        }
    }
}


@media only screen and (max-width: 900px) {
    .benefit {
        padding-bottom: 64px;
        padding-top: 64px;
        &__body {
            font-size: 14px;
        }
        &__header {
            margin: 0;
            margin-bottom: 12px;
        }
        &__subheader {
            font-size: 12px;
            margin-bottom: 6px;
        }
        &__wrapper {
            display: block;
            margin-bottom: 32px;
        }
    }
}


@media only screen and (max-width: 500px) {
    .benefit {
        &__header {
            font-size: 20px;
        }
    }
}
