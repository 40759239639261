.how {
  background-color: #0d121f;
  padding-bottom: 120px;
  padding-top: 150px;
  width: 100%;
}

.how__body {
  color: #90a3bf;
  max-width: 700px;
  width: 100%;
  text-align: center;
  margin-bottom: 60px;
}

.how__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.how__demo {
  background-color: #8e00af;
  color: #fff;
  padding: 14px 28px;
  font-family: jakarta;
  border-radius: 30px;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.how__demo:hover {
  opacity: 0.8;
}

.how__header {
  font-size: 40px;
  color: #fff;
  margin-bottom: 24px;
}

.how__img {
  margin-bottom: 24px;
}

.how__item {
  max-width: 225px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}

.how__item::before {
  content: "1";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* Use Flexbox */
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  /* Center horizontally */
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  width: 50px;
  height: 50px;
  top: calc(0% - 25px);
  left: calc(0% - 25px);
  border-radius: 50%;
  background-color: #8e00af;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  border: 5px solid #0d121f;
}

.how__item-body {
  color: #fff;
  text-align: center;
}

.how__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  margin-bottom: 65px;
}

.how__pricing {
  color: #c3d4e9;
  background-color: #1a202c;
  margin-left: 24px;
  padding: 14px 28px;
  font-family: jakarta;
  border-radius: 30px;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.how__pricing:hover {
  opacity: 0.8;
}

.how__subheader {
  color: #8e00af;
  margin-bottom: 12px;
  font-weight: 600;
  margin-bottom: 50px;
  text-align: center;
}

@media only screen and (max-width: 900px) {
  .how {
    padding-bottom: 64px;
    padding-top: 64px;
  }
  .how__body {
    text-align: center;
  }
  .how__btn-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .how__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .how__demo {
    text-align: center;
    margin-bottom: 10px;
  }
  .how__header {
    font-size: 24px;
    margin-bottom: 12px;
  }
  .how__item {
    margin-bottom: 56px;
  }
  .how__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 0;
  }
  .how__pricing {
    margin: 0;
    text-align: center;
  }
  .how__subheader {
    margin-bottom: 6px;
  }
}

@media only screen and (max-width: 500px) {
  .how__header {
    font-size: 25px;
  }
  .how__body {
    font-size: 12px;
  }
}
