.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
.test {
    background-color: #0d121f;
    position: relative;
    color: #fff;
    height: 100vh;
    &::after {
        content: "";
        position: absolute;
        display: inline-block;
        width: 500px;
        height: 500px;
        bottom: 0;
        right: 0;
        background-image: url("../../Images/test-after.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }

    &::before {
        content: "";
        position: absolute;
        display: inline-block;
        width: 500px;
        height: 500px;
        top: 0;
        left: 0;
        background-image: url("../../Images/test-before.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }

    &__body {
        color: #90a3bf;
        max-width: 500px;
        width: 100%;
        text-align: center;
        margin-bottom: 36px;
    }
    &__btn-wrapper {
        margin-top: 50px;
    }
    &__container {
        padding-top: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    &__header {
        font-size: 40px;
        font-weight: bold;
        margin-bottom: 24px;
    }
    &__item {
        max-width: 320px;
        height: 299px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 32px;
        background-color: #1a202c;
        transition: all 0.3s ease;
        border-radius: 12px;
        margin: 0 auto;
        &:hover {
            transform: scale(1.05);
        }
    }
    &__item-img {
        margin-right: 20px;
    }
    &__item-job {
        font-size: 14px;
        color: #596780;
    }
    &__item-name {
        font-size: 18px;
    }
    &__item-title {
        margin-bottom: 12px;
        font-weight: 600;
    }
    &__item-wrapper {
        display: flex;
        align-items: center;
    }
    &__left {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #1a202c;
        margin-right: 24px;
    }
    &__left-img {
    }
    &__line {
        border: none;
        background: none;
        height: 1px;
        width: 100%;
        background-color: #90a3bf;
        margin-bottom: 24px;
    }
    &__list {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20;
        z-index: 1;
    }
    &__name-wrapper {
    }
    &__right {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #8e00af;
    }
    &__right-img {
    }
    &__subheader {
        color: #8e00af;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 12px;
    }
}

@media only screen and (max-width: 900px) {
    .test {
        &::after {
            display: none;
        }

        &::before {
            display: none;
        }
        &__list {
            display: block;
        }

        &__item {
            margin-bottom: 30px;
        }

        &__header {
            text-align: center;
        }
    }
}

@media only screen and (max-width: 768px) {
    .test {
        &__header {
           font-size: 35px;
        }
    }
}

@media only screen and (max-width: 500px){
 .test{
     &__header{
         font-size: 30px;
     }
     &__body{
         font-size: 14px;
     }
     &__item-title{
         font-size: 18px;
     }
     &__item-body{
         font-size: 15px;
     }
     &__item-name{
         font-size: 16px;
     }
     &__item-job{
         font-size: 12px;
         text-align: center;
     }
 }   
}

@media only screen and (max-width: 400px) {
    .test {
        &__header {
           font-size: 25px;
        }
        &__body{
            font-size: 12px;
        }
    }
}