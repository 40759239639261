.carousel__pricing_options_seemore {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.carousel__seemore_option_text {
  text-decoration: underline;
  cursor: pointer;
}

.carousel__seemore_option_text:hover {
  color: purple;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
