:root {
    --main-gray-color: #272b35;
    --main-white-gray-color: #e7e7e7;
    --main-white-gray2-color: #cfcfcf;
    --main-white-color: #fff;
    --main-primary-color: purple;
    --main-hover-primary-color: rgb(214, 0, 214);
  }
  
  .box-wrapper-registr {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    position: fixed;
    top: -10px;
    left: 0;
  }
  
  .registration-demo_box_back {
    width: 250px;
    height: 50px;
    position: fixed;
    top: calc(50% + 55px);
    right: 0;
    display: flex;
    justify-content: space-between;
    border-radius: 10px 0 0 10px;
    color: var(--main-white-color);
    display: flex;
    align-items: center;
    background-color: var(--main-primary-color);
    cursor: pointer;
    z-index: 5;
    transition: 0.5s;
  }
  
  .registration-demo_box_back:hover {
    background-color: var(--main-hover-primary-color);
  }
  
  .registration_demo_previous_page_icon {
    font-size: 23px;
    width: 80px;
    display: grid;
    place-items: center;
  }
  
  .registration_demo_btn_previous_page_signup {
    color: var(--main-white-color);
    width: 170px;
    display: flex;
    align-items: center;
    font-size: 18px;
    text-transform: capitalize;
  }
  
  .registration-demo-select-language {
    width: 250px;
    border-radius: 10px 0 0 10px;
    height: 50px;
    z-index: 5;
    top: 50%;
    position: fixed;
    cursor: pointer;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: var(--main-white-color);
    /* box-shadow: 0 0 5px gray; */
    border: 1px solid var(--main-primary-color);
  }
  
  .registration-demo-language-option-box {
    width: 50px;
    height: 85%;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .registration-demo-language-option-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .registration-demo-box-shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgb(58, 58, 58);
    z-index: -1;
    opacity: 0.2;
  }
  
  .register-demo-card {
    width: 70%;
    height: 90%;
    background-color: white;
    border-radius: 5px;
    padding: 20px 30px 30px;
    box-sizing: border-box;
    box-shadow: 0 0 11px 3px rgba(0, 0, 0, 0.1);
  }
  
  .registration-demo-card-controller {
    height: 10%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .registration-span-circle-controller-box {
    display: flex;
    height: 100%;
    width: 49.5%;
    align-items: center;
  }
  
  .registration-span-circle-controller-box:first-child {
    width: 49.5%;
    justify-content: end;
  }
  
  .registration-span-circle-controller-box > span {
    border-radius: 50%;
    padding: 6.5px;
  }
  
  .registration-demo-carousel-sections {
    height: 90%;
    scroll-behavior: smooth;
    width: 100%;
    display: flex;
    overflow: hidden;
  }
  
  .registration-demo-carousel-sections > div {
    min-width: 100%;
    height: 100%;
  }
  .registration-demo-carousel-sections > div:first-child {
    display: flex;
    justify-content: space-around;
  }
  
  .registration-demo-card-carousel-left-side-box {
    width: 45%;
    height: 100%;
    display: grid;
    place-items: center;
  }
  
  .registration-demo-left-side-svg-box {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  
  .registration-demo-card-carousel-right-side-box {
    width: 52%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  
  .registration-demo-card-carousel-right-text-box {
    display: flex;
    width: 100%;
    height: 15%;
    flex-direction: column;
    align-items: center;
  }
  
  .registration-demo-card-carousel-right-text-box > h3 {
    font-weight: 600;
    font-size: 30px;
  }
  
  .registration-demo-card-carousel-right-text-box > h4 {
    color: var(--main-gray-color);
    font-weight: 500;
    font-size: 20px;
  }
  
  .registration-demo-form {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .registration-demo-form-input {
    border-radius: 5px;
    padding: 10px;
    border: 2px solid var(--main-white-gray-color);
    font-size: 18px;
    background: var(--main-white-color);
  }
  
  .registration-demo-input-first-column {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .registration-demo-input-first-column > input {
    color: black;
    width: 47%;
  }
  
  .registration-demo-form input:focus {
    border-color: var(--main-primary-color);
    transition: 0.5s;
  }
  
  .registration-demo-input-first-column > input::-webkit-input-placeholder {
    color: var(--main-white-gray2-color);
  }
  
  .registration-demo-select-country-box {
    width: 100%;
    height: 55px;
    position: relative;
    cursor: pointer;
    z-index: 2;
    display: grid;
    place-items: center;
    /* border-radius: 5px; */
    border: 2px solid var(--main-white-gray-color);
    font-size: 18px;
    background: var(--main-white-color);
  }
  
  .registration-demo-default-value-country {
    width: 95%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .registration-demo-country-value-box {
    width: 50%;
  }
  
  .registration-demo-country-value-box > i {
    transition: 0.5s;
  }
  
  .registration-demo-selected-country-text-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
  }
  
  .registration-demo-country-value-box span {
    color: var(--main-gray-color);
    font-size: 18px;
    font-weight: 500;
  }
  
  .registration-demo-countries-box {
    width: 100%;
    height: 500%;
    position: absolute;
    z-index: -1;
    bottom: -500%;
    left: 0;
    display: grid;
    place-items: center;
    background: var(--main-white-color);
    padding: 20px 0;
  }
  
  .registration-demo-wrapper-select-country-box {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .registration-demo-search-country-input-box {
    width: 100%;
    height: 10%;
    position: relative;
  }
  
  .registration-demo-search {
    width: 100%;
    height: 100%;
    color: var(--main-gray-color);
    outline: none;
  }
  
  .registration-demo-search:focus {
    box-shadow: 0 0 10px var(--main-primary-color);
    border: 1px solid var(--main-primary-color);
  }
  
  .registration-demo-search-country-input-box i {
    position: absolute;
    height: 100%;
    right: 0;
    width: 5%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .registration-demo-countries-flags-box {
    width: 100%;
    height: 200px;
    overflow-y: auto;
  }
  
  .registration-demo-select-country-value-box {
    width: 100%;
    color: var(--main-gray-color);
    padding: 5px 0;
    cursor: pointer;
    /* width: 50%;
    display: flex;
    justify-content: space-between; */
  }
  
  .registration-demo-select-country-value-box:hover {
    background-color: var(--main-primary-color);
    color: var(--main-white-color);
  }
  
  .registration-demo-phone-number-input {
    width: 100%;
    position: relative;
    height: auto;
    display: flex;
    align-items: center;
    transition: 0.5s;
    position: relative;
    z-index: 1;
    border-radius: 5px;
  }
  
  .registratin-demo-select-country-phonenumber {
    width: 80%;
    height: 500%;
    position: absolute;
    bottom: -500%;
    left: 0;
    display: grid;
    place-items: center;
    overflow-y: auto;
    background: var(--main-white-color);
  }
  
  .registration-demo-phone-number-input i {
    color: var(--main-gray-color);
  }
  
  .registration-demo-phone-number-input > span {
    cursor: pointer;
    height: 90%;
    top: 2px;
    display: flex;
    align-items: center;
    width: 10%;
    left: 2px;
    background-color: var(--main-white-color);
    position: absolute;
    justify-content: space-around;
  }
  
  .registration-demo-phone-number-input > span:hover {
    background: var(--main-white-gray-color);
  }
  
  .registration-demo-phone-number-input > input::-webkit-input-placeholder {
    color: var(--main-white-gray2-color);
  }
  
  .registration-demo-phone-number-input > input {
    width: 100%;
    padding-left: 12%;
  }
  
  .registration-demo-password-input {
    width: 100%;
  }
  
  .registration-demo-password-input-box {
    position: relative;
    width: 100%;
  }
  
  .registration-demo-password-input-box > input {
    width: 100%;
    padding-right: 30px;
  }
  
  .registration-demo-password-visibility {
    position: absolute;
    height: 100%;
    font-size: 20px;
    width: 30px;
    right: 0;
    top: 0;
    display: grid;
    place-items: center;
    cursor: pointer;
  }
  
  .registration-demo-password-input-value-controller {
    width: 100%;
  }
  
  .registration-demo-input-password-requirements-top-box {
    width: 100%;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(2, auto);
  }
  
  .registration-demo-password-requirement-checkbox {
    display: flex;
    align-items: center;
    margin-top: 10px;
    color: var(--main-gray-color);
  }
  
  .registration-demo-password-requirement-checkbox:nth-child(3) {
    justify-content: end;
  }
  
  .registration-demo-password-checkbox {
    /* background-color: var(--main-white-gray-color); */
    border-radius: 50%;
    padding: 6.5px;
  }
  
  .registration-demo-btn-submit {
    width: 100%;
    padding: 15px 0;
    display: grid;
    place-items: center;
    color: var(--main-white-color);
    font-size: 18px;
    background-color: purple;
  }
  
  .registration-demo-carousel-sections > div:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .registration-demo-second-section-right-side-wrapper-box {
    width: 52%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  
  .registration-demo-carousel-second-section-form {
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  
  .registration-demo-back-box {
    width: 100%;
  }
  
  .registration-demo-back-box > div {
    color: var(--main-primary-color);
    font-weight: 500;
    font-size: 20px;
    display: flex;
    cursor: pointer;
  }
  
  .registration-demo-back-box > p {
    padding: 0 10px;
  }
  
  .registration-demo-company-inn-kpp-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .registration-demo-company-inn-kpp-box > div {
    width: 40%;
  }
  
  .registration-demo-company-inn-kpp-box > div > input {
    border-radius: 10px;
    border: 2px solid var(--main-white-gray-color);
    width: 100%;
    padding: 10px 15px;
    display: grid;
    overflow: hidden;
    place-items: center;
  }
  
  .registration-demo-company-name-input-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .registration-demo-company-name-input-box > input::-webkit-input-placeholder {
    color: var(--main-white-gray2-color);
  }
  
  .registration-demo-company-name-input-box > input {
    width: 100%;
    padding: 10px;
    outline: none;
    color: var(--main-gray-color);
    border: 2px solid var(--main-white-gray-color);
    border-radius: 5px;
    font-size: 18px;
  }
  
  .registration-demo-company-name-input-box > input:focus {
    border-color: var(--main-primary-color);
  }
  
  .registration-demo-company-sphere-select-box {
    width: 100%;
    position: relative;
    cursor: pointer;
    display: grid;
    place-items: center;
    height: 55px;
    padding: 0;
    border: 2px solid var(--main-white-gray-color);
    border-radius: 5px;
    font-size: 18px;
  }
  
  .registration-demo-select-company-value-box {
    width: 100%;
    color: var(--main-gray-color);
    display: grid;
    place-items: center;
  }
  
  .registration-demo-selected-sphere-text__wrapper {
    width: 90%;
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
  
  .registration-demo-selected-sphere-text__wrapper > span {
    flex: 1;
  }
  
  .registration-demo-selected-sphere-text__wrapper > i {
    transition: 0.5s;
  }
  
  .registration-demo-select-sphere-box {
    width: 100%;
    position: absolute;
    left: 0;
    overflow-y: auto;
    height: 400%;
    background-color: var(--main-white-color);
    bottom: -400%;
    box-shadow: 0px 0px 10px var(--main-white-gray-color);
  }
  .registration-demo-company-sphere-option {
    width: 100%;
    padding: 10px;
    color: var(--main-gray-color);
  }
  
  .registration-demo-employee-input-number-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .registration-demo-employee-input-number-box > input {
    width: 100%;
    padding: 10px;
    border: 2px solid var(--main-white-gray-color);
    color: var(--main-gray-color);
    font-size: 18px;
    border-radius: 5px;
  }
  
  .registration-demo-employee-input-number-box
    > input::-webkit-input-placeholder {
    color: var(--main-white-gray2-color);
  }
  
  .registration-demo-employee-input-number-box > input:focus {
    border-color: var(--main-primary-color);
  }
  
  .registration-demo-employees-data-list {
    width: 100%;
  }
  
  .btn-submit-register {
    width: 100%;
    height: 60px;
    /* padding: 15px 0; */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color: var(--main-white-color);
    font-size: 18px;
    background-color: var(--main-primary-color);
  }
  
  .lds-spinner {
    /* change color here */
    color: #1c4c5b;
  }
  .lds-spinner,
  .lds-spinner div,
  .lds-spinner div:after {
    box-sizing: border-box;
  }
  .lds-spinner {
    color: currentColor;
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
    transform: scale(0.45);
  }
  .lds-spinner div {
    transform-origin: 30px 30px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3.2px;
    left: 36.8px;
    width: 4.4px;
    height: 12.6px;
    border-radius: 20%;
    background: currentColor;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  .registration-demo-resend-email-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .registration-demo-btn-resend-email {
    width: 100%;
    height: 40px;
    background: var(--main-white-gray-color);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .registration-demo-btn-resend-email:hover {
    transition: 0.5s;
    background: rgb(192, 192, 192);
  }
  
  .lds-ellipsis,
  .lds-ellipsis div {
    box-sizing: border-box;
  }
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    transform: scale(0.5);
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33.33333px;
    width: 13.33333px;
    height: 13.33333px;
    border-radius: 50%;
    background: var(--main-primary-color);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  
  @media only screen and (max-width: 1250px) {
    .register-demo-card {
      width: 900%;
    }
  }
  
  @media only screen and (max-width: 1100px) {
    .register-demo-card {
      width: 100%;
    }
  }
  
  @media only screen and (max-width: 990px) {
    .registration-demo-card-carousel-left-side-box {
      width: 48%;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .register-demo-card {
      height: 100%;
    }
    .registration-demo-card-carousel-left-side-box {
      display: none;
    }
    .registration-demo-card-carousel-right-side-box {
      width: 80%;
      margin: 0 auto;
    }
    .registration-demo-phone-number-input > span {
      justify-content: center;
    }
    .registration-demo-second-section-right-side-wrapper-box {
      width: 90%;
    }
  }
  
  @media only screen and (max-width: 650px) {
    .registration-demo-country-value-box {
      width: 70%;
      justify-content: space-between;
    }
    .registration-demo-second-section-right-side-wrapper-box {
      width: 95%;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .registration-demo-input-password-requirements-top-box {
      grid-template-columns: repeat(2, auto);
      grid-template-rows: repeat(2, auto);
      font-size: 15px;
    }
    .registration-demo-password-requirement-checkbox:nth-child(3) {
      justify-content: start;
    }
    .registration-demo-carousel-second-section-form {
      width: 90%;
    }
  }
  
  @media only screen and (max-width: 500px) {
    .registration-demo-card-carousel-right-side-box {
      width: 100%;
    }
    .registration-demo-input-password-requirements-top-box {
      grid-template-columns: auto;
      grid-template-rows: repeat(4, auto);
    }
    .registration-demo-carousel-second-section-form {
      width: 100%;
    }
  }