.success {
  padding-top: 120px;
  padding-bottom: 50px;
}

.success__content-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 32px;
}

.success__item-green {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 32px;
  position: relative;
  padding-left: 36px;
}

.success__item-green::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 0;
  top: calc(0% + 5px);
  background-image: url("../../Images/tick-circle.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.success__item-red {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 32px;
  position: relative;
  padding-left: 36px;
}

.success__item-red::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 0;
  top: calc(0% + 5px);
  background-image: url("../../Images/close-circle.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.success__list {
  max-width: 525px;
  width: 100%;
}

.success .success__subheader {
  font-size: 20px;
  font-weight: 600;
  color: #8e00af;
  margin-bottom: 12px;
}

.success .success__header {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 60px;
}

.success .success__btn-wrapper {
  margin-bottom: 20px;
}

.success .success__btn-wrapper .success__with {
  margin-right: 10px;
}

.success .success__btn-wrapper .success__with,
.success .success__btn-wrapper .success__without {
  border: none;
  background: none;
  outline: none;
  font-size: 18px;
  font-weight: 500;
  padding: 14px 30px;
  border-radius: 30px;
  background-color: #f3f5f7;
}

.success .success__btn-wrapper .success__with.active,
.success .success__btn-wrapper .success__without.active {
  background-color: #ae00ff;
  color: #fff;
}

.success .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-transition: opacity 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}

.success .fade-enter {
  opacity: 0;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

.success .fade-enter-active {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: opacity 500ms, -webkit-transform 500ms;
  transition: opacity 500ms, -webkit-transform 500ms;
  transition: opacity 500ms, transform 500ms;
  transition: opacity 500ms, transform 500ms, -webkit-transform 500ms;
}

.success .fade-exit {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.success .fade-exit-active {
  opacity: 0;
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
  -webkit-transition: opacity 500ms, -webkit-transform 500ms;
  transition: opacity 500ms, -webkit-transform 500ms;
  transition: opacity 500ms, transform 500ms;
  transition: opacity 500ms, transform 500ms, -webkit-transform 500ms;
}

@media only screen and (max-width: 900px) {
  .success .content {
    display: block;
  }
  .success {
    padding-top: 64px;
  }
  .success__btn-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .success__content-header {
    font-size: 16px;
  }
  .success .success__header {
    font-size: 24px;
    width: 80%;
    margin-bottom: 32px !important;
  }
  .success__img {
    max-width: 320px;
    width: 100%;
  }
  .success__img-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .success .success__subheader {
    font-size: 12px;
    margin-bottom: 6px;
  }
  .success .success__btn-wrapper .success__with,
  .success .success__btn-wrapper .success__without {
    font-size: 12px;
  }
}

@media only screen and (max-width: 768px) {
  .success__header {
    font-size: 23px !important;
  }
}

@media only screen and (max-width: 500px) {
  .success__header {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 400px) {
  .success__header {
    font-size: 18px !important;
  }
}
