.success {
    padding-top: 120px;
    padding-bottom: 50px;
    .container {
        // Your existing styles
    }

    .success__container {
        // Your existing styles
    }

    &__content-header {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 32px;
    }

    &__item-green {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 32px;
        position: relative;
        padding-left: 36px;
        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            width: 24px;
            height: 24px;
            left: 0;
            top: calc(0% + 5px);
            background-image: url("../../Images/tick-circle.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    &__item-red {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 32px;
        position: relative;
        padding-left: 36px;
        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            width: 24px;
            height: 24px;
            left: 0;
            top: calc(0% + 5px);
            background-image: url("../../Images/close-circle.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    &__list {
        max-width: 525px;
        width: 100%;
    }

    .success__subheader {
        font-size: 20px;
        font-weight: 600;
        color: #8e00af;
        margin-bottom: 12px;
    }

    .success__header {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 60px;
    }

    .success__btn-wrapper {
        margin-bottom: 20px;
        .success__with {
            margin-right: 10px;
        }
        .success__with,
        .success__without {
            border: none;
            background: none;
            outline: none;
            font-size: 18px;
            font-weight: 500;
            padding: 14px 30px;
            border-radius: 30px;
            background-color: #f3f5f7;
            &.active {
                background-color: #ae00ff;
                color: #fff;
            }
        }
    }

    .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    }

    .fade-enter {
        opacity: 0;
        transform: translateY(-10px);
    }
    .fade-enter-active {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 500ms, transform 500ms;
    }
    .fade-exit {
        opacity: 1;
        transform: translateY(0);
    }
    .fade-exit-active {
        opacity: 0;
        transform: translateY(10px);
        transition: opacity 500ms, transform 500ms;
    }

    .content-wrapper {
    }
}

@media only screen and (max-width: 900px) {
    .container {
    }
    .success .content {
        display: block;
    }
    .content-wrapper {
    }
    .success {
        padding-top: 64px;
        &__btn-wrapper {
            display: flex;
            align-items: center;
        }
        &__container {
        }
        &__content-header {
            font-size: 16px;
        }
        .success__header {
            font-size: 24px;
            width: 80%;
            margin-bottom: 32px !important;
        }
        &__img {
            max-width: 320px;
            width: 100%;
        }
        &__img-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &__item-green {
        }
        &__item-red {
        }
        &__list {
        }
        .success__subheader {
            font-size: 12px;
            margin-bottom: 6px;
        }

        .success__btn-wrapper {
            .success__with,
            .success__without {
                font-size: 12px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
  .success{
      &__header{
          font-size: 23px !important;
      }
  }
}

@media only screen and (max-width: 500px) {
    .success{
        &__header{
            font-size: 20px !important;
        }
    }
  }
  @media only screen and (max-width: 400px) {
    .success{
        &__header{
            font-size: 18px !important;
        }
    }
  }