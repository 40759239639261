@keyframes slideInFromBottom {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideInBefore {
    0% {
        transform: translateX(200px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slideInAfter {
    0% {
        transform: translateX(-200px);
    }
    100% {
        transform: translateX(0);
    }
}
