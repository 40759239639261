:root {
    --main-primary-color: rgb(168, 0, 168);
    --main-white-color: white;
    --main-black-color: black;
    --main-styled-white-color: rgb(206, 206, 206);
}

@font-face {
    font-family: "street";
    src: url("../src/Fonts/PlusJakartaSans-Bold.ttf");
}

* {
    font-family: "street", "Roboto", sans-serif !important;
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
}
.container {
    max-width: 1240px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 20px;
    padding-right: 20px;
}

.container:has(.pricing__container) {
    max-width: 1440px;
}

.benefit__list {
    flex-wrap: wrap;
    align-items: center;
}

.test__list {
    display: flex;
    justify-content: space-evenly !important;
    flex-wrap: wrap;
}

.test__list > li {
    margin: 10px 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
html,
body,
p,
li,
ul,
hr,
ol {
    margin: 0;
    padding: 0;
}

image {
    vertical-align: middle;
}

a {
    text-decoration: none;
}

ol,
ul {
    list-style: none;
}

body {
    font-family: jakarta;
}

button {
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
}

.active__link {
    background-color: var(--main-primary-color);
    border-radius: 10px;
    color: var(--main-white-color) !important;
}

.active_mobile_link {
    background-color: var(--main-primary-color);
    color: var(--main-white-color);
}

@media only screen and (max-width: 900px) {
    .benefit__list {
        flex-direction: column;
        align-items: center;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 1fr);
    }
    .benefit__item {
        min-width: 80% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        /* box-shadow: 0 0 10px  rgb(196, 196, 196); */
        padding: 10px;
        margin: 10px auto;
    }
    .benefit__subheader {
        text-align: center !important;
    }
    .benefit__wrapper > p,
    h3 {
        text-align: center !important;
        margin: 0 auto !important;
    }
    .test__list {
        display: grid !important;
        grid-template-columns: 1fr !important;
        grid-template-rows: repeat(3, 1fr) !important;
    }
    .test__list > li {
        min-width: 70% !important;
        margin: 10px auto;
    }
    .pricing__list {
        display: grid !important;
        grid-template-columns: 1fr !important;
        grid-template-rows: repeat(3, 1fr) !important;
    }
    .pricing__list > li {
        min-width: 70% !important;
        margin: 10px auto;
    }
}
