.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.test {
  background-color: #0d121f;
  position: relative;
  color: #fff;
  height: 100vh;
}

.test::after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 500px;
  height: 500px;
  bottom: 0;
  right: 0;
  background-image: url("../../Images/test-after.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.test::before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 500px;
  height: 500px;
  top: 0;
  left: 0;
  background-image: url("../../Images/test-before.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.test__body {
  color: #90a3bf;
  max-width: 500px;
  width: 100%;
  text-align: center;
  margin-bottom: 36px;
}

.test__btn-wrapper {
  margin-top: 50px;
}

.test__container {
  padding-top: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.test__header {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 24px;
}

.test__item {
  max-width: 320px;
  height: 299px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 32px;
  background-color: #1a202c;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 12px;
  margin: 0 auto;
}

.test__item:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.test__item-img {
  margin-right: 20px;
}

.test__item-job {
  font-size: 14px;
  color: #596780;
}

.test__item-name {
  font-size: 18px;
}

.test__item-title {
  margin-bottom: 12px;
  font-weight: 600;
}

.test__item-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.test__left {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #1a202c;
  margin-right: 24px;
}

.test__line {
  border: none;
  background: none;
  height: 1px;
  width: 100%;
  background-color: #90a3bf;
  margin-bottom: 24px;
}

.test__list {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 20;
  z-index: 1;
}

.test__right {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #8e00af;
}

.test__subheader {
  color: #8e00af;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 12px;
}

@media only screen and (max-width: 900px) {
  .test::after {
    display: none;
  }
  .test::before {
    display: none;
  }
  .test__list {
    display: block;
  }
  .test__item {
    margin-bottom: 30px;
  }
  .test__header {
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .test__header {
    font-size: 35px;
  }
}

@media only screen and (max-width: 500px) {
  .test__header {
    font-size: 30px;
  }
  .test__body {
    font-size: 14px;
  }
  .test__item-title {
    font-size: 18px;
  }
  .test__item-body {
    font-size: 15px;
  }
  .test__item-name {
    font-size: 16px;
  }
  .test__item-job {
    font-size: 12px;
    text-align: center;
  }
}

@media only screen and (max-width: 400px) {
  .test__header {
    font-size: 25px;
  }
  .test__body {
    font-size: 12px;
  }
}
