/* space-grotesk-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "space";
    font-style: normal;
    font-weight: 400;
    src: url("../Fonts/fonts/space-grotesk-v16-latin-600.woff2") format("woff2"),
        /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
            url("../Fonts/fonts/space-grotesk-v16-latin-regular.ttf")
            format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* space-grotesk-600 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "space";
    font-style: normal;
    font-weight: 600;
    src: url("../Fonts/fonts/space-grotesk-v16-latin-600.woff2") format("woff2"),
        /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
            url("../Fonts/fonts/space-grotesk-v16-latin-600.ttf")
            format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* space-grotesk-700 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "space";
    font-style: normal;
    font-weight: 700;
    src: url("../Fonts/fonts/space-grotesk-v16-latin-700.woff2") format("woff2"),
        /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
            url("../Fonts/fonts/space-grotesk-v16-latin-700.ttf")
            format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* plus-jakarta-sans-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "jakarta";
    font-style: normal;
    font-weight: 400;
    src: url("../Fonts/fonts/plus-jakarta-sans-v8-latin-regular.woff2")
            format("woff2"),
        /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
            url("../Fonts/fonts/plus-jakarta-sans-v8-latin-regular.ttf")
            format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* plus-jakarta-sans-500 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "jakarta";
    font-style: normal;
    font-weight: 500;
    src: url("../Fonts/fonts/plus-jakarta-sans-v8-latin-500.woff2")
            format("woff2"),
        /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
            url("../Fonts/fonts/plus-jakarta-sans-v8-latin-500.ttf")
            format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* plus-jakarta-sans-600 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "jakarta";
    font-style: normal;
    font-weight: 600;
    src: url("../Fonts/fonts/plus-jakarta-sans-v8-latin-600.woff2")
            format("woff2"),
        /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
            url("../Fonts/fonts/plus-jakarta-sans-v8-latin-600.ttf")
            format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* plus-jakarta-sans-700 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "jakarta";
    font-style: normal;
    font-weight: 700;
    src: url("../Fonts/fonts/plus-jakarta-sans-v8-latin-700.woff2")
            format("woff2"),
        /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
            url("../Fonts/fonts/plus-jakarta-sans-v8-latin-700.ttf")
            format("truetype"); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
