.pricing_options{
    display: flex;
    width: 100vw;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    h1{
        font-size: 50px;
    }
    &__heading_text{
        width: 80%;
        margin: 10px auto;
    }
    &__container{
        width: 90%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 40px;
    }
    &__card{
        display: flex;
        max-width: 350px;
        height: 350px;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background-color: #f3f5f7;
        border-radius: 10px;
        cursor: pointer;
        transition: 0.5s;
        margin: 20px;
        img{
            width: 100%;
            height: 70%;
            object-fit: cover;
            border-radius: 10px;
        }
    }
    &__card:hover{
        transform: scale(1.05);
    }
    &__card_link{
        display: inline-block;
        padding: 10px;
    }
    &__card_box{
        min-height: 30%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__heading_text{
        text-align: center;
        color: var(--main-primary-color);
    }
    &__card_text{
        color: rgb(170, 170, 170);
        padding-top: 10px;
        text-align: center;
    }
}

@media only screen and (max-width: 900px){
    .pricing_options{
        &__container{
            width: 100%;
        }
    }
}

@media only screen and (max-width: 780px){
    .pricing_options{
        &__card{
            max-width: none;
            width: 80%;
            height: 380px;
            img{
                height: 60%;
            }
        } 
    }
}