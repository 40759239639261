$primary-color: purple;
.carousel{
    &__pricing_options_seemore{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    &__seemore_option_text{
        text-decoration: underline;
        cursor: pointer;
        &:hover{
            color: $primary-color;
            transition: 0.5s;
        }
    }
}