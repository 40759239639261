@import url("../../Css/Animation.css");
.hero {
  background-color: #0d121f;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-image: url("../../Images/image.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.hero::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 450px;
  height: 385px;
  background-image: url("../../Images/hero-after.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  -webkit-animation: slideInAfter 1s ease-out;
          animation: slideInAfter 1s ease-out;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 450px;
  height: 450px;
  background-image: url("../../Images/hero-before.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  -webkit-animation: slideInBefore 1s ease-out;
          animation: slideInBefore 1s ease-out;
}

.hero__container {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-animation: slideInFromBottom 1.5s ease-out;
          animation: slideInFromBottom 1.5s ease-out;
}

.hero__demo {
  background-color: #8e00af;
  color: #fff;
  padding: 14px 28px;
  font-family: jakarta;
  border-radius: 30px;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.hero__demo:hover {
  opacity: 0.8;
}

.hero__header {
  font-family: jakarta;
  font-size: 72px;
  margin-top: 70px;
  text-align: center;
  color: #8e00af;
}

.hero__pricing {
  color: #ffffff;
  background-color: #000000;
  margin-left: 24px;
  padding: 14px 28px;
  font-family: jakarta;
  border-radius: 30px;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.hero__pricing:hover {
  opacity: 0.8;
}

.hero__subheader {
  width: 80%;
  font-family: jakarta;
  font-size: 18px;
  text-align: center;
  color: #90a3bf;
  margin-bottom: 40px;
}

@media only screen and (max-width: 1150px) {
  .hero__header {
    font-size: 60px;
  }
  .hero__subheader {
    font-size: 14px;
  }
}

@media only screen and (max-width: 900px) {
  .hero__header {
    font-size: 50px;
  }
  .hero__subheader {
    font-size: 12px;
  }
}

@media only screen and (max-width: 768px) {
  .hero__header {
    font-size: 40px;
  }
}

@media only screen and (max-width: 520px) {
  .hero__header {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .hero__subheader {
    width: 90%;
  }
  .hero__btn-wrapper {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
  .hero__btn {
    width: 40%;
    font-size: 10px !important;
  }
  .hero__demo, .hero__pricing {
    padding: 15px;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 400px) {
  .hero__header {
    font-size: 25px;
  }
  .hero__subheader {
    font-size: 12px;
  }
  .hero__demo, .hero__pricing {
    padding: 10px 15px;
    white-space: nowrap;
  }
}
