.pricing {
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 120px;
        padding-bottom: 120px;
    }
    &__back_btn_box{
        display: flex;
        justify-content: space-evenly;
        font-size: 22px;
        width: 120px;
        background-color: var(--main-primary-color);
        color: var(--main-white-color);
        border-radius: 15px;
        cursor: pointer;
        font-weight: 700
    }
    &__btn_back{
        font-size: 30px;
        display: grid;
        place-items: center;
    }
    &__dec {
        font-size: 14px;
        padding: 4px 12px;
        border-radius: 30px;
        background-color: #e7defe;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 12px;
    }
    &__title{
        font-size: 45px;
        color: purple;
    }
    &__header_slogan{
        padding-top: 30px;
    }
    &_hero_text_description{
        font-size: 20px;
        text-align: center;
        margin: 10px 0;
    }
    &_card_feature_container{
        width: 100%;
        display: flex;
        margin: 20px 0;
    }
    &__keyfeature_icons{
        font-size: 50px;
        padding: 0 10px;
        color: purple;
    }
    &_card_feature_box{
        display: flex;
        max-width: 700px;
        background-color: rgb(247, 247, 247);
        align-items: center;
        justify-content: space-around;
        padding: 20px;
        border-radius: 10px;
    }
    &_feature_heading{
        color: purple;
        padding-bottom: 10px;
    }
    &_feature_text_box{
        display: flex;
        flex-direction: column;
        // align-items: center;
    }
    &_feature_description_text{
        font-size: 18px;
        line-height: 25px;
    }
    &__header {
        font-size: 35px;
        font-weight: bold;
        margin: 20px 0;
    }
    &__item {
        max-width: 330px;
        width: 100%;
        padding: 32px;
        background-color: #f3f5f7;
        border-radius: 12px;
        margin: 20px auto;
    }
    &__item-body {
        margin-bottom: 32px;
    }
    &__item-btn {
        margin-top: 16px;
        padding: 14px;
        background-color: #8e00af;
        width: 100%;
        border-radius: 30px;
        font-weight: 600px;
        color: #fff;
    }

    &__header-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__item-popular {
        padding: 4px 16px;
        background-color: #0d121f;
        color: #fff;
        border-radius: 30px;
        font-weight: 500;
        line-height: 150%;
    }

    &__item-cons {
        margin-bottom: 24px;
        padding-left: 36px;
        position: relative;
        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            width: 24px;
            height: 24px;
            left: 0;
            top: 0;
            background-image: url("../../Images/close-circle.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
    }

    &__item-pros {
        margin-bottom: 24px;
        padding-left: 36px;
        position: relative;
        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            width: 24px;
            height: 24px;
            left: 0;
            top: 0;
            background-image: url("../../Images/tick-circle.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
    }
    &__item-header {
        font-size: 32px;
        font-weight: 600;
        padding-left: 44px;
        position: relative;
        margin-bottom: 12px;
        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            width: 32px;
            height: 32px;
            top: calc(0% + 5px);
            left: 0;
            background-image: url("../../Images/lovely.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
    }
    &__item-list {
        display: flex;
        flex-direction: column;
    }
    &__item-per {
        color: #596780;
        font-size: 18px;
    }
    &__item-price {
        font-size: 40px;
        font-weight: 600;
    }
    &__item-subheader {
        color: #596780;
        margin-bottom: 24px;
    }
    &__item-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
    }
    &__list, &__computer-carousel {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
    }
    &__tablet-carousel, &__phone-carousel{
        display: none;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
    }
    &__subheader {
        font-size: 20px;
        color: #596780;
        margin-bottom: 64px;
    }
    &__text {
        font-size: 20px;
        font-weight: 600;
    }
    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 400px;
        width: 100%;
        margin-bottom: 85px;
    }
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 36px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch {
    position: absolute;
    cursor: pointer;
    top: 2px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 24px;
}

.switch:before {
    position: absolute;
    content: "";
    height: 30px;
    width: 30px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .switch {
    background-color: #6200ea;
}

input:checked + .switch:before {
    transform: translateX(26px);
}

@media only screen and (max-width: 900px) {
    .container {
    }
    .pricing {
        &__container {
            padding-bottom: 50px;
        }
        &__dec {
        }
        &__header {
            text-align: center;
        }
        &__header-wrapper {
        }
        &__item {
            margin-bottom: 32px;
        }
        &__item-body {
        }
        &__item-btn {
        }
        &__item-cons {
        }
        &__item-header {
        }
        &__item-list {
        }
        &__item-per {
        }
        &__item-popular {
        }
        &__item-price {
        }
        &__item-pros {
        }
        &__item-subheader {
        }
        &__item-wrapper {
        }
        &__list {
            display: block;
        }
        &__subheader {
            text-align: center;
            margin-bottom: 30px;
        }
        &__text {
        }
        &__wrapper {
            margin-bottom: 32px;
        }
    }
}

@media only screen and (max-width: 1200px){
    .pricing{
        &__computer-carousel{
            display: none;
        }
        &__tablet-carousel{
            display: flex;
        }
    }
}

@media only screen and (max-width: 820px){
    .pricing{
        &__tablet-carousel{
            display: none;
        }
        &__phone-carousel{
            display: flex;
        }
    }
}


@media only screen and (max-width: 600px){
    .pricing{
        &_card_feature_box{
            flex-direction: column;
            align-content: center;
        }
        &_feature_description_text{
            text-align: center;
        }
        &__title{
            font-size: 35px;
            text-align: center;
        }
        &__header{
            font-size: 25px;
        }
        &_hero_text_description{
            font-size: 18px;
        }
    }
}