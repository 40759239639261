.pricing__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-top: 120px;
  padding-bottom: 120px;
}

.pricing__back_btn_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  font-size: 22px;
  width: 120px;
  background-color: var(--main-primary-color);
  color: var(--main-white-color);
  border-radius: 15px;
  cursor: pointer;
  font-weight: 700;
}

.pricing__btn_back {
  font-size: 30px;
  display: -ms-grid;
  display: grid;
  place-items: center;
}

.pricing__dec {
  font-size: 14px;
  padding: 4px 12px;
  border-radius: 30px;
  background-color: #e7defe;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  font-size: 12px;
}

.pricing__title {
  font-size: 45px;
  color: purple;
}

.pricing__header_slogan {
  padding-top: 30px;
}

.pricing_hero_text_description {
  font-size: 20px;
  text-align: center;
  margin: 10px 0;
}

.pricing_card_feature_container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 20px 0;
}

.pricing__keyfeature_icons {
  font-size: 50px;
  padding: 0 10px;
  color: purple;
}

.pricing_card_feature_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 700px;
  background-color: #f7f7f7;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  padding: 20px;
  border-radius: 10px;
}

.pricing_feature_heading {
  color: purple;
  padding-bottom: 10px;
}

.pricing_feature_text_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.pricing_feature_description_text {
  font-size: 18px;
  line-height: 25px;
}

.pricing__header {
  font-size: 35px;
  font-weight: bold;
  margin: 20px 0;
}

.pricing__item {
  max-width: 330px;
  width: 100%;
  padding: 32px;
  background-color: #f3f5f7;
  border-radius: 12px;
  margin: 20px auto;
}

.pricing__item-body {
  margin-bottom: 32px;
}

.pricing__item-btn {
  margin-top: 16px;
  padding: 14px;
  background-color: #8e00af;
  width: 100%;
  border-radius: 30px;
  font-weight: 600px;
  color: #fff;
}

.pricing__header-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.pricing__item-popular {
  padding: 4px 16px;
  background-color: #0d121f;
  color: #fff;
  border-radius: 30px;
  font-weight: 500;
  line-height: 150%;
}

.pricing__item-cons {
  margin-bottom: 24px;
  padding-left: 36px;
  position: relative;
}

.pricing__item-cons::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 0;
  top: 0;
  background-image: url("../../Images/close-circle.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.pricing__item-pros {
  margin-bottom: 24px;
  padding-left: 36px;
  position: relative;
}

.pricing__item-pros::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 0;
  top: 0;
  background-image: url("../../Images/tick-circle.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.pricing__item-header {
  font-size: 32px;
  font-weight: 600;
  padding-left: 44px;
  position: relative;
  margin-bottom: 12px;
}

.pricing__item-header::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 32px;
  height: 32px;
  top: calc(0% + 5px);
  left: 0;
  background-image: url("../../Images/lovely.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.pricing__item-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.pricing__item-per {
  color: #596780;
  font-size: 18px;
}

.pricing__item-price {
  font-size: 40px;
  font-weight: 600;
}

.pricing__item-subheader {
  color: #596780;
  margin-bottom: 24px;
}

.pricing__item-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 24px;
}

.pricing__list, .pricing__computer-carousel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
}

.pricing__tablet-carousel, .pricing__phone-carousel {
  display: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
}

.pricing__subheader {
  font-size: 20px;
  color: #596780;
  margin-bottom: 64px;
}

.pricing__text {
  font-size: 20px;
  font-weight: 600;
}

.pricing__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  max-width: 400px;
  width: 100%;
  margin-bottom: 85px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 36px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch {
  position: absolute;
  cursor: pointer;
  top: 2px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 24px;
}

.switch:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .switch {
  background-color: #6200ea;
}

input:checked + .switch:before {
  -webkit-transform: translateX(26px);
          transform: translateX(26px);
}

@media only screen and (max-width: 900px) {
  .pricing__container {
    padding-bottom: 50px;
  }
  .pricing__header {
    text-align: center;
  }
  .pricing__item {
    margin-bottom: 32px;
  }
  .pricing__list {
    display: block;
  }
  .pricing__subheader {
    text-align: center;
    margin-bottom: 30px;
  }
  .pricing__wrapper {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 1200px) {
  .pricing__computer-carousel {
    display: none;
  }
  .pricing__tablet-carousel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media only screen and (max-width: 820px) {
  .pricing__tablet-carousel {
    display: none;
  }
  .pricing__phone-carousel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media only screen and (max-width: 600px) {
  .pricing_card_feature_box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-line-pack: center;
        align-content: center;
  }
  .pricing_feature_description_text {
    text-align: center;
  }
  .pricing__title {
    font-size: 35px;
    text-align: center;
  }
  .pricing__header {
    font-size: 25px;
  }
  .pricing_hero_text_description {
    font-size: 18px;
  }
}
