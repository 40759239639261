.how {
    background-color: #0d121f;
    padding-bottom: 120px;
    padding-top: 150px;
    width: 100%;
    &__body {
        color: #90a3bf;
        max-width: 700px;
        width: 100%;
        text-align: center;
        margin-bottom: 60px;
    }
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    &__demo {
        background-color: #8e00af;
        color: #fff;
        padding: 14px 28px;
        font-family: jakarta;
        border-radius: 30px;
        transition: 0.3s ease;

        &:hover {
            opacity: 0.8;
        }
    }
    &__header {
        font-size: 40px;
        color: #fff;
        margin-bottom: 24px;
    }
    &__img {
        margin-bottom: 24px;
    }
    &__item {
        max-width: 225px;
        display: flex;
        flex-direction: column;
        position: relative;
        &::before {
            content: "1";
            display: flex; /* Use Flexbox */
            justify-content: center; /* Center horizontally */
            align-items: center;
            position: absolute;
            width: 50px;
            height: 50px;
            top: calc(0% - 25px);
            left: calc(0% - 25px);
            border-radius: 50%;
            background-color: #8e00af;
            color: #fff;
            font-size: 24px;
            font-weight: 600;
            border: 5px solid #0d121f;
        }
    }
    &__item-body {
        color: #fff;
        text-align: center;
    }
    &__list {
        display: flex;
        // max-width: 950px;
        width: 100%;
        justify-content: space-around;
        margin-bottom: 65px;
    }
    &__pricing {
        color: #c3d4e9;
        background-color: #1a202c;
        margin-left: 24px;
        padding: 14px 28px;
        font-family: jakarta;
        border-radius: 30px;
        transition: 0.3s ease;

        &:hover {
            opacity: 0.8;
        }
    }
    &__subheader {
        color: #8e00af;
        margin-bottom: 12px;
        font-weight: 600;
        margin-bottom: 50px;
        text-align: center;
    }
}

@media only screen and (max-width: 900px) {
    .how {
        padding-bottom: 64px;
        padding-top: 64px;
        &__body {
            text-align: center;
        }
        &__btn-wrapper {
            display: flex;
            width: 100%;
            flex-direction: column;
        }
        &__container {
            display: flex;
            flex-direction: column;
        }
        &__demo {
            text-align: center;
            margin-bottom: 10px;
        }
        &__header {
            font-size: 24px;
            // max-width: 182px;S
            margin-bottom: 12px;
        }
        &__item {
            margin-bottom: 56px;
        }
        &__list {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 0;
        }
        &__pricing {
            margin: 0;
            text-align: center;
        }
        &__subheader {
            margin-bottom: 6px;
        }
    }
}
@media only screen and (max-width: 500px){
    .how{
        &__header{
            font-size: 25px;
        }
        &__body{
            font-size: 12px;
        }
    }
}