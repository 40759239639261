.back-to-top-box {
    width: 50px;
    height: 50px;
    position: fixed;
    right: 30px;
    bottom: 100px;
    z-index: 1;
}

.back-to-top-box > div {
    cursor: pointer;
    background-color: var(--main-white-color);
    color: var(--main-primary-color);
    z-index: 1;
    display: grid;
    place-items: center;
    border-radius: 48.5%;
    width: 100%;
    height: 100%;
    position: relative;
    border: 1px solid var(--main-primary-color);
}

.back-to-top-box > div:hover {
    transition: 0.5s;
    background-color: rgb(180, 3, 180);
    color: var(--main-white-color);
    border: none;
}

.social-media-apps-controller, .styled-social-media-apps-controller {
    width: 50px;
    height: 50px;
    position: fixed;
    right: 15px;
    bottom: 150px;
    z-index: 1;
}

.social-media-apps-controller > div, .styled-social-media-apps-controller > div {
    cursor: pointer;
    background-color: var(--main-primary-color);
    color: var(--main-white-color);
    z-index: 1;
    display: grid;
    place-items: center;
    border-radius: 48.5%;
    width: 100%;
    height: 100%;
    position: relative;
    border: 1px solid var(--main-primary-color);
}

.social-media-apps-controller::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    border: 1px solid var(--main-primary-color);
    top: 0;
    border-radius: 48.5%;
    animation-name: circleAnimation;
    animation-duration: 0.7s;
    animation-iteration-count: infinite;
}

.media-apps-controller-wrapper{
    width: 50px;
    height: 100px;
    position: absolute;
    right: 0;
}

.social-media-apps-controller-box{
    width: 40px;
    height: 100%;
    border-radius: 10px;
    background-color: var(--main-white-color);
    border: 1px solid var(--main-primary-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
}

.social-media-apps-controller-box > div{
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    color: var(--main-primary-color);
    transition: 0.5s;
}

.social-media-apps-controller-box > div a{
    text-decoration: none;
    color: var(--main-primary-color);
}

.social-media-apps-controller-box > div:hover{
    background-color: var(--main-styled-white-color);
}

.social-media-apps-controller-pointer{
    height: 100%;
    width: 10px;
    display: grid;
    place-items: center;
}

.social-media-apps-controller-pointer >div{
    border-top: 5px solid transparent;
    border-left: 12px solid var(--main-primary-color);
    border-bottom: 5px solid transparent;
}

@keyframes circleAnimation {
    to {
        transform: scale(1.5);
    }
}
