.footer {
    margin-top: 40px;
    &__body {
        font-size: 18px;
        color: #596780;
        margin: 10px 0;
    }
    &__plain2do_wrapper{
        display: flex;
    }
    &_itpark-logo{
        width: 160px;
        height: 50px;
        margin: 0 20px;
        object-fit: cover;
    }
    &_icons__wrapper{
        margin: 10px auto;
        font-size: 30px;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    &_icons__wrapper a{
        color: gray;
        cursor: pointer;
        text-decoration: none;
        transition: 0.5s;
    }
    &__linedin_icon:hover{
        color: dodgerblue;
    }
    &__youtube_icon:hover{
        color: red;
    }
    &__instagram_icon:hover{
        background: linear-gradient(red, blue);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        color: rgb(255, 0, 157);
}
    &__bottom-line {
        width: 1px;
        height: 20px;
        border: none;
        background: none;
        background-color: #000;
    }
    &__bottom-link {
        font-size: 18px;
        font-weight: 600;
        color: #000;
    }
    &__bottom-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 650px;
        width: 100%;
    }
    &__bottom-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__container {
        padding-bottom: 50px;
        padding-top: 100px;
    }
    &__copyright {
        color: #596780;
    }
    &__inside-header {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 32px;
    }

    &__inside-item {
        margin-bottom: 24px;
    }

    &__inside-link {
        color: #596780;
    }
    &__line {
        margin-top: 40px;
        margin-bottom: 32px;
        background: none;
        border: none;
        background-color: #59678054;
        height: 1px;
    }
    &__list {
        display: flex;
        justify-content: space-evenly;
        max-width: 700px;
        width: 100%;
    }
    &__logo {
        margin-right: 8px;
    }
    &__logo-text {
        font-family: space;
        font-size: 24px;
        font-weight: bold;
    }
    &__logo-wrapper {
        display: flex;
        align-items: center;
    }
    &__upper-left {
        max-width: 200px;
        width: 100%;
    }
    &__upper-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

@media only screen and (max-width: 964px){
    .footer{
        &__upper-left{
            max-width: 1440px;
            display: flex;
            flex-direction: column;
            align-content: center;
        }
        &_icons__wrapper{
            width: 300px;
        }
        &__logo-wrapper{
            justify-content: space-evenly;
        }
        &__body{
            text-align: center;
            margin: 20px 0;
        }
        &__list{
            margin: 20px auto;
        }
    }
}

@media only screen and (max-width: 600px) {
    .footer {
        &__body {
        }
        &__bottom-item {
            margin-bottom: 32px;
        }
        &__bottom-line {
            display: none;
        }
        &__bottom-link {
        }
        &__bottom-list {
            display: block;
        }
        &__bottom-wrapper {
            display: block;
        }
        &__container {
            padding-top: 0;
        }
        &__copyright {
        }
        &__inside-header {
        }
        &__inside-item {
        }
        &__inside-link {
        }
        &__inside-list {
        }
        &__item {
        }
        &__line {
        }
        &__list {
            flex-wrap: wrap;
        }
        &__logo {
        }
        &__logo-text {
        }
        &__logo-wrapper {
        }
        &__upper-left {
            margin-bottom: 48px;
        }
    }
}

@media only screen and (max-width: 375px){
    .footer{
        &__logo-wrapper{
            height: 170px;
            flex-direction: column;
            justify-content: space-evenly;
        }
        &_icons__wrapper{
            width: 90%;
        }
    }
}

@media only screen and (max-width: 325px){
    .footer{
        &__list{
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }
        &__list > li{
            margin-top:10px;
        }
    }
}