@import url("../../Css/Animation.css");

.hero {
    background-color: #0d121f;
    // margin-top: 50px;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("../../Images/image.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 450px;
        height: 385px;
        background-image: url("../../Images/hero-after.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        animation: slideInAfter 1s ease-out;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 450px;
        height: 450px;
        background-image: url("../../Images/hero-before.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        animation: slideInBefore 1s ease-out;
    }

    &__container {
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        animation: slideInFromBottom 1.5s ease-out;
    }

    &__demo {
        background-color: #8e00af;
        color: #fff;
        padding: 14px 28px;
        font-family: jakarta;
        border-radius: 30px;
        transition: 0.3s ease;

        &:hover {
            opacity: 0.8;
        }
    }

    &__header {
        font-family: jakarta;
        font-size: 72px;
        margin-top: 70px;
        text-align: center;
        color: #8e00af;
    }

    &__pricing {
        color: #ffffff;
        background-color: #000000;
        margin-left: 24px;
        padding: 14px 28px;
        font-family: jakarta;
        border-radius: 30px;
        transition: 0.3s ease;

        &:hover {
            opacity: 0.8;
        }
    }

    &__subheader {
        width: 80%;
        font-family: jakarta;
        font-size: 18px;
        text-align: center;
        color: #90a3bf;
        margin-bottom: 40px;
    }
}

@media only screen and (max-width: 1150px) {
    .hero {
        &__header {
            font-size: 60px;
        }
        &__subheader{
            font-size: 14px;
        }
    }
}


@media only screen and (max-width: 900px) {
    .hero {
        &__header {
            font-size: 50px;
        }
        &__subheader{
            font-size: 12px;
        }
    }
}

@media only screen and (max-width: 768px){
    .hero{
        &__header{
            font-size: 40px;
        }
    }
}



@media only screen and (max-width: 520px){
    .hero{
        &__header{
            font-size: 30px;
            margin-bottom: 20px;
        }
        &__subheader{
            width: 90%;
        }
        &__btn-wrapper{
            width: 100%;
            display: flex;
            justify-content: space-around;
        }
        &__btn{
            width: 40%;
            font-size: 10px !important;
        }
        &__demo, &__pricing{
            padding: 15px;   
            white-space: nowrap; 
        }
    }

}


@media only screen and (max-width: 400px){
    .hero{
        &__header{
            font-size: 25px;
        }
        &__subheader{
            font-size: 12px;
        }
        &__demo, &__pricing{
            padding: 10px 15px;   
            white-space: nowrap; 
        }
    }

}