.header {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;
    background-color: var(--main-white-color);
    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;
    }

    &__demo {
        padding: 14px 24px;
        border-radius: 30px;
        background-color: #8e00af;
        color: #fff;
        font-family: jakarta;
        font-size: 16px;
        margin-left: 30px;
        text-decoration: none;
        white-space: nowrap;
    }

    &__dropdown {
        position: relative;

        &:hover &__dropdown-content {
            display: flex;
            flex-direction: column;
            transform: translateY(5px);
            opacity: 1;
        }
    }

    &__dropdown-content {
        display: none;
        position: absolute;
        font-family: jakarta;
        background-color: #ffffff50;
        min-width: 130px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        z-index: 1;
        border-radius: 4px;
        transform: translateY(-20px);
        opacity: 0;
        transition: transform 0.3s ease, opacity 0.3s ease;
    }

    &__dropdown-link {
        padding: 7px 17px;
        display: block;
        color: #000;
        text-decoration: none;
        transition: 0.3s ease;

        &:hover {
            opacity: 0.8;
            background-color: #f1f1f1;
        }
    }

    &__item-link {
        font-family: "jakarta";
        font-size: 18px;
        text-decoration: none;
        padding: 5px 0;
        transition: 0.3s ease;
        &:hover {
            opacity: 0.8;
        }
    }
    &__item-NavLink{
        padding: 10px;
        color: #8e00af;
        cursor: pointer;
    }
    &__list {
        display: flex;
        align-items: center;
        max-width: 600px;
        width: 100%;
        justify-content: space-between;
    }

    &__login {
        font-family: jakarta;
        font-size: 16px;
        color: #8e00af;
        text-decoration: none;
    }

    &__logo-text {
        margin-left: 20px;
        font-family: "Space";
        font-size: 24px;
        color: #8e00af;
    }

    &__logo-wrapper {
        display: flex;
        align-items: center;
    }

    /* Burger Menu */
    &__burger {
        display: none;
        flex-direction: column;
        justify-content: space-around;
        width: 24px;
        height: 24px;
        background: transparent;
        border: none;
        cursor: pointer;
        z-index: 11;
    }

    &__burger-line {
        width: 24px;
        height: 2px;
        background-color: #8e00af;
        border-radius: 10px;
        transition: all 0.3s linear;
    }

    /* Mobile Menu */
    &__mobile-menu {
        display: none;
        position: fixed;
        top: 0;
        right: -100%; /* Start off-screen */
        width: 250px;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.9);
        box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
        transition: right 0.3s ease; /* Adjusted transition */
        z-index: 10;
        padding-top: 60px;
    }

    &__mobile-menu--open {
        display: block;
        right: 0%; /* Open position */
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9;
    }

    &__mobile-list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__mobile-item {
        margin-bottom: 20px;
        width: 90%;
        overflow: hidden;
        border-radius: 10px;
        height: 40px;
        color: #8e00af;
        a, span {
            display: grid;
            place-items: center;
            width: 100%;
            height: 100%;
            font-family: jakarta;
            font-size: 18px;
            text-decoration: none;
            transition: color 0.3s ease;
        }
    }

    &__mobile-btn-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        width: 100%;
    }

    &__mobile-login {
        font-family: jakarta;
        font-size: 16px;
        text-decoration: none;
        margin-bottom: 20px;
        color: #8e00af;
        padding: 14px 0;
        border-radius: 30px;
        border: 1px solid #8e00af;
        width: 80%;
        text-align: center;
    }

    &__mobile-demo {
        padding: 14px 0;
        text-align: center;
        border-radius: 30px;
        width: 80%;
        background-color: #8e00af;
        color: #fff;
        font-family: jakarta;
        font-size: 16px;
        text-decoration: none;
        margin-bottom: 20px;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #6d0087;
        }
    }

    @media only screen and (max-width: 1150px) {
        &__list {
            display: none;
        }

        &__burger {
            display: flex;
        }

        &__btn-wrapper {
            display: none;
        }
    }
}
