@import url("../../Css/Animation.css");
.benefit {
  padding-top: 120px;
  padding-bottom: 120px;
}

.benefit__body {
  max-width: 550px;
  width: 100%;
  font-size: 14px;
  line-height: 150%;
}

.benefit__header {
  font-size: 35px;
  font-weight: bold;
  margin-top: 12px;
  line-height: 150%;
  margin-right: 160px;
  -webkit-animation: slideInFromBottom 1s ease-out;
          animation: slideInFromBottom 1s ease-out;
  text-transform: capitalize;
}

.benefit__image {
  margin-bottom: 25px;
}

.benefit__item {
  max-width: 384px;
  margin-top: 20px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.benefit__item:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.benefit__item-body {
  color: #596780;
}

.benefit__item-header {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 12px;
  color: #040815;
}

.benefit__list {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.benefit__subheader {
  font-size: 20px;
  color: #8e00af;
}

.benefit__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 65px;
}

@media only screen and (max-width: 1200px) {
  .benefit__header {
    font-size: 25px;
  }
}

@media only screen and (max-width: 900px) {
  .benefit {
    padding-bottom: 64px;
    padding-top: 64px;
  }
  .benefit__body {
    font-size: 14px;
  }
  .benefit__header {
    margin: 0;
    margin-bottom: 12px;
  }
  .benefit__subheader {
    font-size: 12px;
    margin-bottom: 6px;
  }
  .benefit__wrapper {
    display: block;
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 500px) {
  .benefit__header {
    font-size: 20px;
  }
}
