.footer {
  margin-top: 40px;
}

.footer__body {
  font-size: 18px;
  color: #596780;
  margin: 10px 0;
}

.footer__plain2do_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.footer_itpark-logo {
  width: 160px;
  height: 50px;
  margin: 0 20px;
  -o-object-fit: cover;
     object-fit: cover;
}

.footer_icons__wrapper {
  margin: 10px auto;
  font-size: 30px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.footer_icons__wrapper a {
  color: gray;
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.footer__linedin_icon:hover {
  color: dodgerblue;
}

.footer__youtube_icon:hover {
  color: red;
}

.footer__instagram_icon:hover {
  background: -webkit-gradient(linear, left top, left bottom, from(red), to(blue));
  background: linear-gradient(red, blue);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #ff009d;
}

.footer__bottom-line {
  width: 1px;
  height: 20px;
  border: none;
  background: none;
  background-color: #000;
}

.footer__bottom-link {
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.footer__bottom-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  max-width: 650px;
  width: 100%;
}

.footer__bottom-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.footer__container {
  padding-bottom: 50px;
  padding-top: 100px;
}

.footer__copyright {
  color: #596780;
}

.footer__inside-header {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 32px;
}

.footer__inside-item {
  margin-bottom: 24px;
}

.footer__inside-link {
  color: #596780;
}

.footer__line {
  margin-top: 40px;
  margin-bottom: 32px;
  background: none;
  border: none;
  background-color: #59678054;
  height: 1px;
}

.footer__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  max-width: 700px;
  width: 100%;
}

.footer__logo {
  margin-right: 8px;
}

.footer__logo-text {
  font-family: space;
  font-size: 24px;
  font-weight: bold;
}

.footer__logo-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.footer__upper-left {
  max-width: 200px;
  width: 100%;
}

.footer__upper-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media only screen and (max-width: 964px) {
  .footer__upper-left {
    max-width: 1440px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-line-pack: center;
        align-content: center;
  }
  .footer_icons__wrapper {
    width: 300px;
  }
  .footer__logo-wrapper {
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
  }
  .footer__body {
    text-align: center;
    margin: 20px 0;
  }
  .footer__list {
    margin: 20px auto;
  }
}

@media only screen and (max-width: 600px) {
  .footer__bottom-item {
    margin-bottom: 32px;
  }
  .footer__bottom-line {
    display: none;
  }
  .footer__bottom-list {
    display: block;
  }
  .footer__bottom-wrapper {
    display: block;
  }
  .footer__container {
    padding-top: 0;
  }
  .footer__list {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .footer__upper-left {
    margin-bottom: 48px;
  }
}

@media only screen and (max-width: 375px) {
  .footer__logo-wrapper {
    height: 170px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
  }
  .footer_icons__wrapper {
    width: 90%;
  }
}

@media only screen and (max-width: 325px) {
  .footer__list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .footer__list > li {
    margin-top: 10px;
  }
}
